
import { computed, defineComponent, inject } from "vue";
import OrdersNav from '@/components/OrdersNav/OrdersNav.vue';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components: { OrdersNav },
    setup() {
        const store = inject('store') as Store;
        return {
            navItems: computed(() => store.state.postStations)
        }
    },
});
